import axiosInstance from './api-controller';

/**
 * Get Selected Language Lsit.
 */
const selectedLanguageApi = async (cultureCode: string ) => {
    try {
        const response = await axiosInstance.get('/Language/GetTranslations/' + cultureCode);
        return response;
    } catch (e) {
        throw e;
    }
}

export { selectedLanguageApi }