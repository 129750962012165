import Cookies from "js-cookie";

import {
  GET_SELECTED_LANGUAGE_LIST,
  SET_SELECTED_LANGUAGE,
  GET_SELECTED_LANGUAGE_LIST_SUCCESS,
  SET_SIGNALR_CONNECTION,
  SET_BROWSER_SELECTED_LANGUAGE,
  SET_DEFAULT_DENTIST,
  SET_SEARCH_TEXT,
} from "../actions";
import { setValue } from "../../helpers/utils";

const initailCultureMapper = (culture: string, browser: boolean) => {
  const globleLang = Cookies.get('globleLang');
  let sortedLang: string;

  if (culture.startsWith('en')) {
    sortedLang = 'En_Us';
  } else if (culture.startsWith('de')) {
    sortedLang = 'De_ch';
  } else {
    sortedLang = 'De_ch';
  }

  if (browser) {
    setValue('globleLang', sortedLang);
    return sortedLang;
  } else {
    if (globleLang) {
      return sortedLang = globleLang;
    } else {
      setValue('globleLang', sortedLang);
      return sortedLang;
    }
  }

}

const INIT_STATE = {
  loading: false,
  selectedLanguage: initailCultureMapper(window.navigator.language, false),
  languageSelectedList: undefined,
  signalRConnetion: undefined,
  defaultDentist: null,
  searchText: ""
};

interface IAction {
  type: string;
  payload: any;
}

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case GET_SELECTED_LANGUAGE_LIST:
      return { ...state, loading: true };
    case SET_SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case SET_BROWSER_SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: initailCultureMapper(action.payload, true) };
    case GET_SELECTED_LANGUAGE_LIST_SUCCESS:
      return { ...state, languageSelectedList: action.payload };
    case SET_SIGNALR_CONNECTION:
      return { ...state, signalRConnetion: action.payload };
    case SET_DEFAULT_DENTIST:
      return { ...state, defaultDentist: action.payload };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    default:
      return state;
  }
};