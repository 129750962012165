import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ProtectedRoute } from "./helpers/auth-helper";

import RouteRegistry from "./constants/route-registry";

const ChatClient = React.lazy(() => import("./views/chat-client"));
const Dentist = React.lazy(() => import("./views/dentist"));
const SignIn = React.lazy(() => import("./views/sign-in"));

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          path={RouteRegistry.dentist.path}
          render={(props) => <Dentist {...props} />}
        />
        {/* <ProtectedRoute
          path={RouteRegistry.dentist.path}
          component={<Dentist />}
        /> */}
        <Route
          path={RouteRegistry.clientChat.path}
          exact
          render={(props) => <ChatClient {...props} />}
        />
        {/* <ProtectedRoute
          path={RouteRegistry.clientChat.path}
          component={<ChatClient />}
        /> */}
        <ProtectedRoute path={RouteRegistry.landing.path} component={SignIn} />
        {/* <Route
          path={RouteRegistry.landing.path}
          exact
          render={(props) => <SignIn {...props} />}
        /> */}
        <Redirect to={RouteRegistry.landing.path} />
      </Switch>
    </Router>
  );
};

export default Routes;
