import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import dentistSagas from "./dentist/saga";
import conversationSagas from "./conversation/saga";
import templatesSagas from "./templates/saga";
import addUserSaga from "./addUser/saga";
import statusMessagesSaga from "./statusMessages/saga";
import coreSaga from "./core/saga";

export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    dentistSagas(),
    conversationSagas(),
    templatesSagas(),
    addUserSaga(),
    statusMessagesSaga(),
    coreSaga(),
  ]);
}
