import React from 'react';

const Layout = (props: any) => {

  return (
    <div className="container">
      <div className="app-layout">
        {props.children}
      </div>
    </div>
  );
  
}

export default Layout;