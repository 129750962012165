import { EventChannel } from "redux-saga";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { initConversationSrvc } from "../../services/twilio-service";
import { getConversationDentist } from "../../api/dentist";
import { cerateConversationAPI, createConversation, enableChatBotAPI } from "../../api/client";
import { getAccessTokenSuccess, initConversationSuccess } from "./actions";
import { getCurrentUser, logDebug, userRoleDentist } from "../../helpers/utils";
import { getAccessToken, getCurrConversation } from "./reducer";

import { INIT_CONVERSATION, GET_ACCESS_TOKEN_SUCCESS, SET_ENABLE_SMYLOR_BOT, CREATE_CONVERSATION } from "../actions";
import { UserRole } from "../../constants/user-role";
import { setDefaulDentist } from "../core/actions";

let createConEventChannel: EventChannel<any>;

/**
 * Watches for the GET_ACCESS_TOKEN_SUCCESS action
 */
export function* watchAccessTokenFetched() {
  yield takeLatest(GET_ACCESS_TOKEN_SUCCESS, handleConversationCreated);
}

function* handleConversationCreated(action: any): any {
  const { accessToken, clientIdentity, dentistIdentity } = action.payload;

  createConEventChannel = yield call(initConversationSrvcAsync, accessToken);

  while (true) {
    try {
      const res = yield take(createConEventChannel);
      yield put(
        initConversationSuccess({
          ...res,
          clientIdentity,
          dentistIdentity,
        })
      );
    } catch (error: any) {
      logDebug(">>> Error", error);
    }
  }
}

/**
 * Requests a new Twilio conversation object
 * @param accessToken string
 * @returns 
 */
const initConversationSrvcAsync = async (accessToken: string) => {
  return await initConversationSrvc(accessToken);
};

/**
 * Watches for INIT_CONVERSATION action
 */
export function* watchInitConversation() {
  yield takeLatest(INIT_CONVERSATION, getToken);
}

const getTokenAsync = async (
  obj: { conversationId: number, culture: string },
  userId: number | undefined,
  role: number,
  email?: string | null
) => {

  if (role === UserRole.CLIENT) {
    return await createConversation({
      clientId: parseInt(`${userId}`),
      // dentistId: participantId,
      clientEmail: email,
      id: obj.conversationId,
      culture: obj.culture
    });
  } else if (userRoleDentist(role)) {
    return await getConversationDentist({
      // clientId: participantId,
      dentistId: parseInt(`${userId}`),
      id: obj.conversationId
    });
  }
};

/**
 * Starts initializing a conversation
 * @param action 
 */
function* getToken(action: any): any {
  const user = getCurrentUser();

  try {
    // Ends the active Twilio conversation if any
    const conversationClient = yield select(getCurrConversation);
    if (conversationClient) {
      conversationClient.shutdown();
    }

    const chatId = user?.role === UserRole.CLIENT
      ? `C${user!.chatUserId}D${action.payload.conversationId}`
      : `D${user!.id}C${action.payload.conversationId}`;

    let clientIdentity;
    let dentistIdentity;
    let accessToken;

    // Checks for a previously initialized chat details.
    const chatFound = yield select(getAccessToken, chatId);

    if (chatFound) {
      // Chat details exists.
      clientIdentity = chatFound.clientIdentity;
      dentistIdentity = chatFound.dentistIdentity;
      accessToken = chatFound.accessToken;
    } else {
      // Get a new access token.
      const userId = user?.role === UserRole.CLIENT ? user!.chatUserId : user!.id;

      const response = yield call(
        getTokenAsync,
        action.payload,
        userId,
        user!.role,
        user!.email
      );

      accessToken = response.data.accessToken;
      clientIdentity = response.data.clientIdentity;
      dentistIdentity = response.data.dentistIdentity;
    }

    yield put(
      getAccessTokenSuccess({ accessToken, clientIdentity, dentistIdentity, chatId })
    );

    // createConEventChannel = yield call(
    //   initConversationSrvcAsync,
    //   accessToken
    // );

    // while (true) {
    //   try {
    //     const res = yield take(createConEventChannel);
    //     yield put(
    //       initConversationSuccess({
    //         ...res,
    //         clientIdentity: clientIdentity,
    //         dentistIdentity: dentistIdentity,
    //       })
    //     );
    //   } catch (error: any) {
    //     logDebug(">>> Error", error);
    //   }
    // }
  } catch (error) {
    logDebug(">>> Fetching dentist list failed", error);
  }
}

/**
 * ENABLE CHATT BOT FROM CLIENT SIDE
 */
export function* watchEnableChatBot() {
  yield takeEvery(SET_ENABLE_SMYLOR_BOT, enableChatBot);
};

const enableChatBotAsync = async (data: {conversationId: number, culture: string}) => {
  return await enableChatBotAPI(data);
};

function* enableChatBot(action: any): any {
  try {
    yield call(enableChatBotAsync, action.payload);
  } catch (error) {
    logDebug(">>> Enable chatbot fialed", null);
  }
};

//create new chat

export function* watchCreateNewConveration() {
  yield takeEvery(CREATE_CONVERSATION, createNewConversation);
};

const createNewChatAsync = async (data: any) => {
  return await cerateConversationAPI(data);
};

function* createNewConversation(action: any): any {
  try {
    const res = yield call(createNewChatAsync, action.payload);
    yield put(setDefaulDentist(res.data.conversationId));
  } catch (error) {
    logDebug(">>> update ChatBot status Failed", null);
  }
}

export default function* rootSaga() {
  yield all([fork(watchInitConversation), fork(watchAccessTokenFetched), fork(watchEnableChatBot), fork(watchCreateNewConveration)]);
}