// import { ITemplate } from "../../models/template";
import {
  OPEN_ADD_USER_POPUP,
  CLOSE_ADD_USER_POPUP,
  ADD_CHAT_USER,
  ADD_CHAT_USER_SUCCESS,
  REMOVE_CHAT_USER,
  GET_ACTIVE_CHAT_USERS,
  GET_ACTIVE_CHAT_USERS_SUCCESS,
  UPDATE_ACTIVE_CHAT_USERS,
} from "../actions";

const INIT_STATE = {
  usersLoading: false,
  users: [],
  updateModal: {
    isOpen: false,
    data: null,
  },
};

interface IAction {
  type: string;
  payload: any;
}

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case OPEN_ADD_USER_POPUP: {
      return { ...state, updateModal: { isOpen: true, data: null } };
    }
    case CLOSE_ADD_USER_POPUP: {
      return { ...state, updateModal: { isOpen: false, data: null } };
    }
    case ADD_CHAT_USER:
    case GET_ACTIVE_CHAT_USERS:
    case REMOVE_CHAT_USER: {
      return { ...state, usersLoading: true };
    }
    case GET_ACTIVE_CHAT_USERS_SUCCESS: {
      return { ...state, usersLoading: false, users: action.payload };
    }
    case UPDATE_ACTIVE_CHAT_USERS: {
      return { ...state, users: [...state.users, action.payload] };
    }
    default:
      return state;
  }
};
