import { IUpdateChatBotData, IUpdatedChatBotData } from "../../models/dentist";
import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_DENTISTS,
  GET_DENTISTS_SUCCESS,
  SET_CLIENT_NOTIFICATION,
  SET_DENTIST_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_PARTICIPANT_LIST,
  UPDATE_NEW_PARTICIPANT_LIST,
  UPDATE_CHATBOT_STATUS,
  UPDATE_CHATBOT_STATUS_SUCCESS
} from "../actions";

export const getDentists = () => ({
  type: GET_DENTISTS,
  payload: {},
})

export const getDentistsSuccess = (payload: []) => ({
  type: GET_DENTISTS_SUCCESS,
  payload: payload,
})

export const getClients = () => ({
  type: GET_CLIENTS,
  payload: {},
})

export const getClientsSuccess = (payload: []) => ({
  type: GET_CLIENTS_SUCCESS,
  payload: payload,
})

export const setDentistNotification = (payload: any) => ({
  type: SET_DENTIST_NOTIFICATION,
  payload: payload,
})

export const setClientNotification = (payload: any) => ({
  type: SET_CLIENT_NOTIFICATION,
  payload: payload,
})

export const updateNotification = (payload: any) => ({
  type: UPDATE_NOTIFICATION,
  payload: payload,
})

// Dentist Cliet list update action

export const updateParticipantList = (payload: any) => ({
  type: UPDATE_PARTICIPANT_LIST,
  payload: payload,
})

export const updateNewParticipantList = (payload: any) => ({
  type: UPDATE_NEW_PARTICIPANT_LIST,
  payload: payload,
})

// Update Chatbot Status

export const updateChatBotStatus = (obj: IUpdateChatBotData) => ({
  type: UPDATE_CHATBOT_STATUS,
  payload: obj,
});

export const updateChatBotStatusSuccess = (payload: IUpdatedChatBotData) => ({
  type: UPDATE_CHATBOT_STATUS_SUCCESS,
  payload: payload,
});