import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";

import { activeChatUsersSuccess } from "./actions";
import { logDebug, getCurrentUser } from "../../helpers/utils";

import {
  ADD_CHAT_USER,
  GET_ACTIVE_CHAT_USERS,
  REMOVE_CHAT_USER,
} from "../actions";
import {
  getActiveChatUsers,
  deletActiveChatUser,
  inviteAddChatUser,
} from "../../api/addUser";
import { IAddChatUser } from "../../models/addUser";
// import { ITemplate } from "../../models/template";

/**
 * BEGIN: Get Active Chat Users
 */
export function* watchGetActiveChatUsers() {
  yield takeEvery(GET_ACTIVE_CHAT_USERS, getActiveChatUsersList);
}
const getActiveChatUsersAsync = async (id: number) => {
  return await getActiveChatUsers(id);
};

function* getActiveChatUsersList(action: any): any {
  try {
    const res = yield call(getActiveChatUsersAsync, action.payload);
    yield put(activeChatUsersSuccess(res.data));
  } catch (error) {
    logDebug(">>> GET Active Users List Failed", null);
  }
}

// /**
//  * BEGIN: Remove Chat User
export function* watchDeleteActiveChatUser() {
  yield takeEvery(REMOVE_CHAT_USER, getUpdatedChatUsersList);
}

const deleteActiveChatUserAsync = async (data: {
  conversationId: number;
  participantId: number;
}) => {
  return await deletActiveChatUser(data.conversationId, data.participantId);
};

function* getUpdatedChatUsersList(action: any): any {
  try {
    const res = yield call(deleteActiveChatUserAsync, action.payload);
    yield put(activeChatUsersSuccess(res.data));
  } catch (error) {
    logDebug(">>> GET updated Active Users List Failed", null);
  }
}

// /**
//  * BEGIN: Add Chat User
//  */
export function* watchAddActiveChatUser() {
  yield takeEvery(ADD_CHAT_USER, getUpdatedChatUsers);
}

const addActiveChatUserAsync = async (data: IAddChatUser) => {
  const user = getCurrentUser();
  const obj: IAddChatUser = {
    conversationId: data.conversationId,
    userType: user?.role,
    participantEmail: data.participantEmail,
    culture: data.culture
  };
  return await inviteAddChatUser(obj);
};

function* getUpdatedChatUsers(action: any): any {
  try {
    const res = yield call(addActiveChatUserAsync, action.payload);
    yield put(activeChatUsersSuccess(res.data));
  } catch (error) {
    logDebug(">>> GET Add Active User to List Failed", null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetActiveChatUsers),
    fork(watchDeleteActiveChatUser),
    fork(watchAddActiveChatUser),
  ]);
}
