import axiosInstance from "./api-controller";

// Get Active Chat Users
export const getActiveChatUsers = async (conversationId: number | null) => {
  try {
    const response = await axiosInstance.get(
      "/Participant/GetParticipantsInChat/" + conversationId
    );
    return response;
  } catch (e) {
    throw e;
  }
};

// Delete Chat User
export const deletActiveChatUser = async (
  conversationId: number | null,
  participantId: number | null
) => {
  try {
    const response = await axiosInstance.post(
      "/Participant/RemoveParticipantsInChat/" +
        conversationId +
        "/" +
        participantId
    );
    return response;
  } catch (e) {
    throw e;
  }
};

// Invite Chat User
export const inviteAddChatUser = async (obj: any) => {
  try {
    const response = await axiosInstance.post("/Participant/Invite", obj);
    return response;
  } catch (e) {
    throw e;
  }
};
