import React, { useCallback } from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser, userRoleDentist } from "./utils";
import RouteRegistry from "../constants/route-registry";
import { UserRole } from "../constants/user-role";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {

  const currentUser = getCurrentUser();

  const setComponent = useCallback((props: any) => {

    if (currentUser) {
      if (userRoleDentist(currentUser.role)) {
        return (
          <Redirect
            to={{
              pathname: `${RouteRegistry.dentist.path}`,
              state: { from: props.location },
            }}
          />
        );
      } else if (currentUser.role === UserRole.CLIENT) {
        return (
          <Redirect
            to={{
              pathname: `${RouteRegistry.clientChat.path}`,
              state: { from: props.location },
            }}
          />
        );
      } else if (currentUser.role === UserRole.ADMIN){
        window.open(`${process.env.REACT_APP_SMYLOR_URL}`, "_self");
      }
    } else {
      window.open(`${process.env.REACT_APP_SMYLOR_URL}?returnTo=${window.location.href}`, "_self");
      return (
        <Component {...props} />
      );
    }
  }, [JSON.stringify(currentUser)]);

  return <Route {...rest} render={setComponent} />;
};

const initOAuthSignOut = () => {
  console.log('initOAuthSignOut');
  window.open(process.env.REACT_APP_OAUTH_SIGNOUT_URL, "_blank");
};

const authSignOutEmailNotVarified = () => {
  console.log('authSignOutEmailNotVarified');
  window.open(process.env.REACT_APP_OAUTH_SIGNOUT_URL_EMAIL_NOT_VERIFIED, "_blank");
};

export { ProtectedRoute, initOAuthSignOut, authSignOutEmailNotVarified };
