import { combineReducers } from "redux";

import auth from "./auth/reducer";
import dentist from "./dentist/reducer";
import conversation from "./conversation/reducer";
import templates from "./templates/reducer";
import addUser from "./addUser/reducer";
import statusMessage from "./statusMessages/reducer";
import coreReducer from "./core/reducer";

const reducers = combineReducers({
    auth,
    dentist,
    conversation,
    templates,
    addUser,
    statusMessage,
    coreReducer
});

export default reducers;