import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { selectedLanguageApi } from "../../api/core";
import { logDebug } from "../../helpers/utils";
import { GET_SELECTED_LANGUAGE_LIST } from "../actions";
import { getLanguageSelectedListSuccess } from "./actions";

/**
 * BEGIN: Get Language List 
 */
export function* watchGetSelectedLanguageList() {
  yield takeEvery(GET_SELECTED_LANGUAGE_LIST, getSelectedLanguageList);
}

const getTemplatesAsync = async (data: any) => {
  return await selectedLanguageApi(data);
};

function* getSelectedLanguageList(action: any): any {
  try {
    const res = yield call(getTemplatesAsync, action.payload);
    yield put(getLanguageSelectedListSuccess(res.data));
  } catch (error) {
    logDebug(">>> get Selected Language failed", null);
  }
};



export default function* rootSaga() {
  yield all([
    fork(watchGetSelectedLanguageList)
  ]);
}
