import { ITemplate } from "../../models/template";
import {
  SORT_TEMPLATES,
  CLOSE_TEMPLATE_MODAL,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  GET_TEMPLATES, GET_TEMPLATES_SUCCESS,
  INIT_CREATE_TEMPLATE,
  INIT_UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  GET_TEMPLATES_CATEGORIES,
  GET_TEMPLATES_CATEGORIES_SUCCESS,
  UPDATE_TEMPLATES_CATEGORY,
  DELETE_TEMPLATES_CATEGORY,
  UPDATE_TEMPLATES_CATEGORY_SUCCESS,
  DELETE_TEMPLATES_CATEGORY_SUCCESS,
  GET_TAGLIST_SUCCESS,
} from "../actions";

const INIT_STATE = {
  loading: false,
  templates: [],
  tagList: [],
  categories: [],
  updateModal: {
    isOpen: false,
    data: null,
  },
  updateOrCreateSucces: false
};

interface IAction {
  type: string;
  payload: any;
}

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case GET_TEMPLATES:
    case GET_TEMPLATES:
    case GET_TEMPLATES_CATEGORIES:
    case UPDATE_TEMPLATES_CATEGORY:
    case DELETE_TEMPLATES_CATEGORY:
      return { ...state, loading: true };
    case GET_TEMPLATES_SUCCESS:
      return { ...state, loading: false, templates: [...action.payload], updateOrCreateSucces: false };
    case GET_TAGLIST_SUCCESS:
      return { ...state, loading: false, tagList: [...action.payload] };
    case CREATE_TEMPLATE_SUCCESS: {
      const updatedArr: ITemplate[] = [...state.templates];
      updatedArr.push(action.payload);
      return { ...state, templates: updatedArr, updateModal: { isOpen: false, data: null }, updateOrCreateSucces: true };
    }
    case UPDATE_TEMPLATE_SUCCESS: {
      const updatedArr: ITemplate[] = [...state.templates];
      const index = updatedArr.findIndex((t: ITemplate) => t.id === action.payload.id);
      updatedArr[index] = action.payload;
      return { ...state, templates: updatedArr, updateModal: { isOpen: false, data: null }, updateOrCreateSucces: true };
    }
    case DELETE_TEMPLATE_SUCCESS: {
      const updatedArr: ITemplate[] = [...state.templates];
      const index = updatedArr.findIndex((t: ITemplate) => t.id === action.payload);
      updatedArr.splice(index, 1);
      return { ...state, templates: updatedArr, updateOrCreateSucces: true };
    }
    case GET_TEMPLATES_CATEGORIES_SUCCESS: {
      return { ...state, categories: action.payload, updateOrCreateSucces: false };
    }
    case INIT_UPDATE_TEMPLATE: {
      const tempFound = state.templates.find((item: ITemplate) => item.id === action.payload);

      return { ...state, updateModal: { isOpen: true, data: tempFound, } };
    }
    case INIT_CREATE_TEMPLATE: {
      return { ...state, updateModal: { isOpen: true, data: null } };
    }
    case CLOSE_TEMPLATE_MODAL: {
      return { ...state, updateModal: { isOpen: false, data: null } };
    }
    case SORT_TEMPLATES: {
      return { ...state, templates: [...action.payload] };
    }
    case SORT_TEMPLATES: {
      return { ...state, templates: [...action.payload] };
    }
    case UPDATE_TEMPLATES_CATEGORY_SUCCESS: {
      const upatedCategoryList = state.categories.map((category: any) => {
        if (category.id === action.payload.id) {
          return { ...category, categoryName: action.payload.categoryName }
        } else return category
      })
      return { ...state, loading: false, categories: upatedCategoryList };
    }
    case DELETE_TEMPLATES_CATEGORY_SUCCESS: {
      return { ...state, loading: false, categories: action.payload };
    }
    default:
      return state;
  }
};
