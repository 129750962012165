import { GET_CLIENTS, GET_CLIENTS_SUCCESS, GET_DENTISTS, GET_DENTISTS_SUCCESS, UPDATE_CHATBOT_STATUS_SUCCESS, UPDATE_NEW_PARTICIPANT_LIST, UPDATE_NOTIFICATION, UPDATE_PARTICIPANT_LIST } from "../actions";

const INIT_STATE = {
  loading: false,
  dentists: [],
  clients: [],
  notification: {}
};

interface IAction {
  type: string;
  payload: any;
}

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case GET_DENTISTS:
    case GET_CLIENTS:
      return { ...state, loading: true };
    case GET_DENTISTS_SUCCESS:
      {
        const notification: any = {}
        action.payload.forEach((client: any) => {
          notification[client.id] = client.messageCount
        });

        return { ...state, loading: false, dentists: [...action.payload], notification };
      }
    case GET_CLIENTS_SUCCESS:
      {
        const notification: any = {}
        action.payload.forEach((client: any) => {
          notification[client.id] = client.messageCount
        });
        return { ...state, loading: false, clients: [...action.payload], notification };
      }
    case UPDATE_PARTICIPANT_LIST:
      let newParticipantList;
      if (state.clients.length > 0) {
        newParticipantList = { clients: [...action.payload] }
      } else if (state.dentists.length > 0) {
        newParticipantList = { dentists: [...action.payload] }
      }
      return {
        ...state,
        loading: false,
        ...newParticipantList
      }
    case UPDATE_NOTIFICATION:
      return { ...state, notification: { ...state.notification, [action.payload.conversationId]: action.payload.messageCount } };
    case UPDATE_NEW_PARTICIPANT_LIST:
      let updatedParticipantList;
      let findtheNewChat;
      if (state.clients.length > 0) {
        findtheNewChat = state.clients.find((client: any) => client.id === action.payload.id);
        if (findtheNewChat)
          updatedParticipantList = { clients: state.clients }
        else
          updatedParticipantList = { clients: [action.payload, ...state.clients] }
      } else if (state.dentists.length > 0) {
        findtheNewChat = state.clients.find((client: any) => client.id === action.payload.id);
        if (findtheNewChat)
          updatedParticipantList = { clients: state.dentists }
        else
          updatedParticipantList = { dentists: [action.payload, ...state.dentists] }
      }
      return {
        ...state,
        loading: false,
        notification: { ...state.notification, [action.payload.id]: action.payload.messageCount },
        ...updatedParticipantList
      }
    case UPDATE_CHATBOT_STATUS_SUCCESS:
      const newClientList = state.clients.map((client: any) => {
        if (client.id === action.payload.id) {
          return action.payload;
        } else {
          return client;
        };
      })
      return { ...state, clients: newClientList }
    default:
      return state;
  }
};
