import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { createTemplateSuccess, deleteTemplateCategorySuccess, deleteTemplateSuccess, getCategories, getCategoriesSuccess, getTagListSuccess, getTemplatesSuccess, updateTemplateCategorySuccess, updateTemplateSuccess } from "./actions";
import {
  getCurrentUser,
  logDebug,
} from "../../helpers/utils";

import { DELETE_TEMPLATE, GET_TEMPLATES, CREATE_UPDATE_TEMPLATE, SORT_TEMPLATES, GET_TEMPLATES_CATEGORIES, UPDATE_TEMPLATES_CATEGORY, DELETE_TEMPLATES_CATEGORY, GET_TAGLIST } from "../actions";
import { listTemplates, deleteTemplateById, createTemplate, updateTemplate, sortTemplatesOrder, listCategories, updateTemplateCategoryAPI, deleteTemplateCategoryAPI, listTaglist } from "../../api/dentist";
import { ITemplate } from "../../models/template";


/**
 * BEGIN: Get templates
 */
export function* watchGetTemplates() {
  yield takeEvery(GET_TEMPLATES, getTemplateList);
}

const getTemplatesAsync = async (culture: string) => {
  const user = getCurrentUser();
  return await listTemplates(user!.id, culture);
};

function* getTemplateList(action: any): any {
  try {
    const res = yield call(getTemplatesAsync, action.payload);
    yield put(getTemplatesSuccess(res.data));
  } catch (error) {
    logDebug(">>> Authorizing dentist failed", null);
  }
};

/**
 * BEGIN: Delete template
 */
export function* watchDeleteTemplate() {
  yield takeEvery(DELETE_TEMPLATE, deleteTemplate);
};

const deleteTemplateAsync = async (id: number) => {
  return await deleteTemplateById(id);
};

function* deleteTemplate(action: any): any {
  try {
    const res = yield call(deleteTemplateAsync, action.payload);
    yield put(deleteTemplateSuccess(action.payload));
  } catch (error) {
    logDebug(">>> Deleting template failed", null);
  }
};

/**
 * BEGIN: Create or update template
 */
export function* watchCreateOrUpdateTemplate() {
  yield takeEvery(CREATE_UPDATE_TEMPLATE, createOrupdateTemplate);
};

const createTemplateAsync = async (template: any) => {
  return await createTemplate(template);
};

const updateTemplateAsync = async (template: any) => {
  return await updateTemplate(template);
};

function* createOrupdateTemplate(action: any): any {
  try {
    const user = getCurrentUser();
    if (action.payload.templateId) {
      const res = yield call(updateTemplateAsync, {...action.payload, dentistId: user!.id});
      yield put(updateTemplateSuccess(res.data));
    } else {
      const res = yield call(createTemplateAsync, { ...action.payload, dentistId: user!.id });
      yield put(createTemplateSuccess(res.data));
    }
  } catch (error) {
    logDebug(">>> Creating / updating template failed", null);
  }
};

/**
 * BEGIN: Create or update template
 */
 export function* watchSortTemplates() {
  yield takeEvery(SORT_TEMPLATES, sortTemplates);
};

const sortTemplatesAsync = async (data: any) => {
  return await sortTemplatesOrder(data);
};

function* sortTemplates(action: any): any {
  try {
    const templateIdsArr = action.payload.map((item: ITemplate) => item.id);
    yield call(sortTemplatesAsync, { templateIds: templateIdsArr });
  } catch (error) {
    logDebug(">>> Sorting templates failed", null);
  }
};

/**
 * BEGIN: Get Categories
 */
export function* watchGetCategories() {
  yield takeEvery(GET_TEMPLATES_CATEGORIES, getCategoriesList);
};

const getCategoriesAsync = async (culture: string) => {
  const user = getCurrentUser();
  return await listCategories(user!.id, culture);
};

function* getCategoriesList(action: any): any {
  try {
    const res = yield call(getCategoriesAsync, action.payload);
    yield put(getCategoriesSuccess(res.data));
  } catch (error) {
    logDebug(">>> get Categories dentist failed", null);
  }
};

// update category name

export function* watchUpdateTemplateCategory() {
  yield takeEvery(UPDATE_TEMPLATES_CATEGORY, updateTemplateCategoy);
};

const updateTemlateCategoryAsync = async (data: any) => {
  return await updateTemplateCategoryAPI(data);
};

function* updateTemplateCategoy(action: any): any {

  try {
    const res = yield call(updateTemlateCategoryAsync, action.payload);
    yield put(updateTemplateCategorySuccess(res.data));
  } catch (error) {
    logDebug(">>> update Category dentist failed", null);
  }
}

// delete category name
export function* watchDeleteTemplateCategory() {
  yield takeEvery(DELETE_TEMPLATES_CATEGORY, deleteTemplateCategory);
};

const deleteTemplateCategoryAsync = async (data: any) => {
  return await deleteTemplateCategoryAPI(data);
};

function* deleteTemplateCategory(action: any): any {

  try {
    const res = yield call(deleteTemplateCategoryAsync, action.payload);
    yield put(deleteTemplateCategorySuccess(res.data));
  } catch (error) {
    logDebug(">>> update Category dentist failed", null);
  }
};

/**
 * BEGIN: Get taglist
 */
export function* watchGetTagList() {
  yield takeEvery(GET_TAGLIST, getTagListFunc);
}

const getTagListAsync = async () => {
  return await listTaglist();
};

function* getTagListFunc(action: any): any {
  try {
    const res = yield call(getTagListAsync);
    yield put(getTagListSuccess(res.data));
  } catch (error) {
    logDebug(">>> Authorizing dentist failed", null);
  }
};

export default function* rootSaga() {
  yield all([
    fork(watchGetTemplates),
    fork(watchGetTagList),
    fork(watchDeleteTemplate),
    fork(watchCreateOrUpdateTemplate),
    fork(watchSortTemplates),
    fork(watchGetCategories),
    fork(watchUpdateTemplateCategory),
    fork(watchDeleteTemplateCategory),
  ]);
}
