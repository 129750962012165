/** DENTIST */
export const GET_DENTISTS = 'GET_DENTISTS';
export const GET_DENTISTS_SUCCESS = 'GET_DENTISTS_SUCCESS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const UPDATE_CHATBOT_STATUS = 'UPDATE_CHATBOT_STATUS';
export const UPDATE_CHATBOT_STATUS_SUCCESS = 'UPDATE_CHATBOT_STATUS_SUCCESS';

/** CONVERSATION */
export const INIT_CONVERSATION = 'INIT_CONVERSATION';
export const INIT_CONVERSATION_SUCCESS = 'INIT_CONVERSATION_SUCCESS';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const SHOW_CHAT_LIST_MENU = 'SHOW_CHAT_LIST_MENU';
export const REMOVE_CONVERSATION_DATA = 'REMOVE_CONVERSATION_DATA';
export const SET_SUGGETION_MESSAGE = 'SET_SUGGETION_MESSAGE';
export const SET_EXPAND_SUGGESTION = 'SET_EXPAND_SUGGESTION';
export const SET_AI_LOADING_SUGGESTION_DATA = 'SET_AI_LOADING_SUGGESTION_DATA';
export const SET_AI_SUGGESTION_DATA = 'SET_AI_SUGGESTION_DATA';
export const SET_TYPING_PARTICIPANT = 'SET_TYPING_PARTICIPANT';
export const REMOVE_TYPING_PARTICIPANT = 'REMOVE_TYPING_PARTICIPANT';
export const SET_TYPING_SMYLOR_BOT = 'SET_TYPING_SMYLOR_BOT';
export const SET_ENABLE_SMYLOR_BOT = 'SET_ENABLE_SMYLOR_BOT';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';

// Add conversation chatbot data
export const ADD_CONVERSATION_CHATBOT_DATA = 'ADD_CONVERSATION_CHATBOT_DATA';

/** AUTH */
export const INIT_USER = 'INIT_USER';
export const GET_USER_ID = 'GET_USER_ID';
export const SET_USER_ID = 'SET_USER_ID';
export const INIT_USER_STATUS = 'INIT_USER_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const AUTHORIZE_DENTIST = 'AUTHORIZE_DENTIST';
export const AUTHORIZE_DENTIST_SUCCESS = 'AUTHORIZE_DENTIST_SUCCESS';

export const STATUS_CHANGED = 'STATUS_CHANGED';
export const STATUS_CHANGED_SUCCESS = 'STATUS_CHANGED_SUCCESS';
export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';

/** CHAT TEMPLATES */
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TAGLIST = 'GET_TAGLIST';
export const GET_TAGLIST_SUCCESS = 'GET_TAGLIST_SUCCESS';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const INIT_UPDATE_TEMPLATE = 'INIT_UPDATE_TEMPLATE';
export const INIT_CREATE_TEMPLATE = 'INIT_CREATE_TEMPLATE';
export const CLOSE_TEMPLATE_MODAL = 'CLOSE_TEMPLATE_MODAL';
export const CREATE_UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const SORT_TEMPLATES = 'SORT_TEMPLATES';
export const GET_TEMPLATES_CATEGORIES = 'GET_TEMPLATES_CATEGORIES';
export const GET_TEMPLATES_CATEGORIES_SUCCESS = 'GET_TEMPLATES_CATEGORIES_SUCCESS';
export const UPDATE_TEMPLATES_CATEGORY = 'UPDATE_TEMPLATES_CATEGORY';
export const UPDATE_TEMPLATES_CATEGORY_SUCCESS = 'UPDATE_TEMPLATES_CATEGORY_SUCCESS';
export const DELETE_TEMPLATES_CATEGORY = 'DELETE_TEMPLATES_CATEGORY';
export const DELETE_TEMPLATES_CATEGORY_SUCCESS = 'DELETE_TEMPLATES_CATEGORY_SUCCESS';




/**CHAT HISTORY */

export const CHECK_CHAT_HISTORY = 'CHECK_CHAT_HISTORY';

/** ADD USER */

export const OPEN_ADD_USER_POPUP = 'OPEN_ADD_USER_POPUP';
export const CLOSE_ADD_USER_POPUP = 'CLOSE_ADD_USER_POPUP';
export const ADD_CHAT_USER = 'ADD_CHAT_USER';
export const ADD_CHAT_USER_SUCCESS = 'ADD_CHAT_USER_SUCCESS';
export const REMOVE_CHAT_USER = 'REMOVE_CHAT_USER';
export const GET_ACTIVE_CHAT_USERS = 'GET_ACTIVE_CHAT_USERS';
export const GET_ACTIVE_CHAT_USERS_SUCCESS = 'GET_ACTIVE_CHAT_USERS_SUCCESS';
export const UPDATE_ACTIVE_CHAT_USERS = 'UPDATE_ACTIVE_CHAT_USERS';

/** Notifiactions */

export const SET_DENTIST_NOTIFICATION = 'SET_DENTIST_NOTIFICATION';
export const SET_CLIENT_NOTIFICATION = 'SET_CLIENT_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

/** Particpants list update */
export const UPDATE_PARTICIPANT_LIST = 'UPDATE_PARTICIPANT_LIST';
/** Particpants New Participant */
export const UPDATE_NEW_PARTICIPANT_LIST = 'UPDATE_NEW_PARTICIPANT_LIST';

/** Status Messages */
export const ADD_STATUS_MESSAGE = 'ADD_STATUS_MESSAGE';
export const ADD_STATUS_MESSAGE_SUCCESS = 'ADD_STATUS_MESSAGE_SUCCESS';

export const GET_STATUS_MESSAGE = 'GET_STATUS_MESSAGE';
export const GET_STATUS_MESSAGE_SUCCESS = 'GET_STATUS_MESSAGE_SUCCESS';

export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_LANGUAGE_LIST_SUCCESS = 'GET_LANGUAGE_LIST_SUCCESS';

/** Core Reducer  */

export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE';
export const SET_BROWSER_SELECTED_LANGUAGE = 'SET_BROWSER_SELECTED_LANGUAGE';
export const GET_SELECTED_LANGUAGE_LIST = 'GET_SELECTED_LANGUAGE_LIST';
export const GET_SELECTED_LANGUAGE_LIST_SUCCESS = 'GET_SELECTED_LANGUAGE_LIST_SUCCESS';
export const SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION';
export const SET_DEFAULT_DENTIST= 'SET_DEFAULT_DENTIST';
export const REMOVE_DEFAULT_DENTIST= 'REMOVE_DEFAULT_DENTIST';
export const SET_SEARCH_TEXT= 'SET_SEARCH_TEXT';
