import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { authorizeDentist, getCurrentDentistStatus, getUpdateUserIdAPI, updatedentistStatus } from "../../api/dentist";
import { authorizeDentistSuccess, getUserStatusSuccess, initUserStatus, setUserId, userStatusSuccess } from "./actions";
import {
  getCurrentUser,
  logDebug,
  removeValue,
  setCurrentUser,
  setValue,
  userRoleDentist,
} from "../../helpers/utils";

import { AUTHORIZE_DENTIST, INIT_USER, SIGNOUT, CHECK_CHAT_HISTORY, STATUS_CHANGED, GET_STATUS, GET_USER_ID } from "../actions";
import RouteRegistry from "../../constants/route-registry";
import { IUser } from "../../models/user";
import { authSignOutEmailNotVarified, initOAuthSignOut } from "../../helpers/auth-helper";
import Cookies from 'js-cookie';
import { getChatHistory } from '../../api/client';

/**
 * BEGIN: Authorize dentist.
 */
export function* watchAuthorizeDentist() {
  yield takeEvery(AUTHORIZE_DENTIST, getToken,);
}

const authorizeDentistAsync = async (token: string) => {
  return await authorizeDentist(token);
};

function* getToken(action: any): any {
  const { token, history } = action.payload;
  console.log('------------function* getToken--', 'token', token);
  try {
    const response = yield call(authorizeDentistAsync, token);

    const user: IUser = {
      id: response.data.id,
      email: response.data.email,
      username: response.data.name,
      nickName: response.data.nickName,
      picture: response.data.picture,
      emailVerified: response.data.emailVerified,
      status: response.data.status,
      role: response.data.userType,
    };

    if (user.username && user.email) {
      const sesToken = response.data.tokenData.access_token;
      if (user.emailVerified) {

        setCurrentUser(user);
        setValue(process.env.REACT_APP_USER_SESSION!, sesToken);
        yield put(authorizeDentistSuccess(user));

        if (userRoleDentist(user.role)) {
          history.push(`${RouteRegistry.dentist.path}${RouteRegistry.dentist.subroutes.dentistChat.path}`);
        } else {
          const clientIdentity = Cookies.get('clientIdentity');
          if (clientIdentity && user.email) {
            Cookies.remove('clientIdentity');
            const obj: any = { clientIdentity, email: user.email }
            const chatHistory = getChatHistory(obj);
          }
          history.push(`${RouteRegistry.clientChat.path}`);
        }
      } else
        authSignOutEmailNotVarified()
    } else
      initOAuthSignOut();
  } catch (error) {
    logDebug(">>> Authorizing dentist failed1", null);
    history.push(`${RouteRegistry.landing.path}`);

  }
}

/**
 * BEGIN: Signout
 */
export function* watchSignout() {
  yield takeEvery(SIGNOUT, endCurrentUserSession);
}

function* endCurrentUserSession(action: any): any {
  // const user = getCurrentUser();
  const history = action.payload;
  console.log('endCurrentUserSession');
  setCurrentUser(null);
  removeValue(process.env.REACT_APP_USER_SESSION!);
  removeValue(process.env.REACT_APP_USER_INFO!);
  // if (user?.role === UserRole.DENTIST) {
  window.open(
    process.env.REACT_APP_SMYLOR_URL,
    "_self"
  );
  //   initOAuthSignOut();
  // } else {

  // }
}
/**
 * END: Signout
 */

/**
 * CHECK CHAT HISTORY
 */
export function* checkUnsavedChatHistory() {
  yield takeEvery(CHECK_CHAT_HISTORY, checkChatHistory);
}

function* checkChatHistory() {
  try {
    const strSession = Cookies.get('u-in');
    const User = strSession ? JSON.parse(strSession) : null;
    const clientIdentity = Cookies.get('dentistIdentity');
    if (clientIdentity && User.email) {
      Cookies.remove('dentistIdentity');
      const obj = {
        clientIdentity,
        email: User.email
      }
      const updatedChat = getChatHistory(obj);
    }


  } catch (error) {
    logDebug(">>> Authorizing dentist failed", null);
  }
}
/*
END : CHAT HISTORY
/

/**
 * BEGIN: Initialize user
 */
export function* watchInitUser() {
  yield takeEvery(INIT_USER, initUser);
}

function* initUser(action: any): any {
  const user = action.payload;
  if (!user) {
    yield put(initUserStatus(false, null));
  } else if (user && !user.username && !user.email) {
    setCurrentUser(null);
    removeValue(process.env.REACT_APP_USER_SESSION!);
    removeValue(process.env.REACT_APP_USER_ROLE!);

    if (userRoleDentist(user?.role)) {
      initOAuthSignOut();
    } else {
      window.location.replace("/");
    }
  } else {
    setCurrentUser(user)
    yield put(initUserStatus(true, user));
  }

}

/*
END : Initialize user
/
/**
 * BEGIN: Get User Id
 */
export function* watchUserIdUpdate() {
  yield takeEvery(GET_USER_ID, userIdUpdate);
}

const userIdUpdateAsync = async (data: string) => {
  return await getUpdateUserIdAPI(data);
};

function* userIdUpdate(action: any): any {

  try {
    const res = yield call(userIdUpdateAsync, action.payload);
    if (res) {
      yield put(setUserId(res.data))
    };
  } catch (error) {
    logDebug(">>> update status failed", null);
  }
}
/*
END : Get User Id
/

/**
 * BEGIN: status update
 */
export function* watchstatusUpdate() {
  yield takeEvery(STATUS_CHANGED, statusUpdate);
}

const statusUpdateAsync = async (data: any) => {
  return await updatedentistStatus(data);
};

function* statusUpdate(action: any): any {
  const user = getCurrentUser();
  const statusData = { email: user?.email, status: action.payload.key, isReset: action.payload.isReset }
  try {
    const res = yield call(statusUpdateAsync, statusData);
    if (res) {
      yield put(userStatusSuccess(action.payload))
    };
  } catch (error) {
    logDebug(">>> update status failed", null);
  }
}
/*
END : status update
/

/**
 * BEGIN: GET CURRENT STATUS
 */

export function* watchGetCurrentStatus() {
  yield takeEvery(GET_STATUS, getCurrentStatus);
}

const getCurrentStatusAsync = async (data: any) => {
  return await getCurrentDentistStatus(data);
};

function* getCurrentStatus(action: any): any {
  const user = getCurrentUser();
  try {
    const res = yield call(getCurrentStatusAsync, user?.id);
    if (res) {
      yield put(getUserStatusSuccess(res.data));
    };
  } catch (error) {
    logDebug(">>> get current status failed", null);
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchAuthorizeDentist),
    fork(watchSignout),
    fork(watchInitUser),
    fork(checkUnsavedChatHistory),
    fork(watchstatusUpdate),
    fork(watchGetCurrentStatus),
    fork(watchUserIdUpdate),
  ]);
}
