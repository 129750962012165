import { IRemoveChatUser, IAddChatUser } from "../../models/addUser";
import { ITemplate } from "../../models/template";
import {
  OPEN_ADD_USER_POPUP,
  CLOSE_ADD_USER_POPUP,
  ADD_CHAT_USER,
  REMOVE_CHAT_USER,
  GET_ACTIVE_CHAT_USERS,
  GET_ACTIVE_CHAT_USERS_SUCCESS,
  UPDATE_ACTIVE_CHAT_USERS
} from "../actions";

export const openAddUserModal = () => ({
  type: OPEN_ADD_USER_POPUP,
});

export const closeAddUserModal = () => ({
  type: CLOSE_ADD_USER_POPUP,
});

export const addChatUser = (obj: IAddChatUser) => ({
  type: ADD_CHAT_USER,
  payload: obj,
});

export const removeChatUser = (obj: IRemoveChatUser) => ({
  type: REMOVE_CHAT_USER,
  payload: obj,
});

export const activeChatUsers = (conversationId: number) => ({
  type: GET_ACTIVE_CHAT_USERS,
  payload: conversationId,
});

export const activeChatUsersSuccess = (users: any) => ({
  type: GET_ACTIVE_CHAT_USERS_SUCCESS,
  payload: users,
});

export const updateActiveChatUsers = (data: any) => ({
  type: UPDATE_ACTIVE_CHAT_USERS,
  payload: data,
});
