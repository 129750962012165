import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { addStatusMessageApi, getLanguageListApi, getStatusMessageApi } from "../../api/statusMessage";

import {
  getCurrentUser,
  logDebug,
} from "../../helpers/utils";
import { IGetStatusMessageData, IStatusMessageData } from "../../models/statusMessage";

import { ADD_STATUS_MESSAGE, GET_LANGUAGE_LIST, GET_STATUS_MESSAGE } from "../actions";
import { addStatusMessageSuccess, getLanguageListSuccess, getStatusMessageSuccess } from "./actions";

/**
 * BEGIN: set status message update
 */
export function* watchsetStatusMessage() {
  yield takeEvery(ADD_STATUS_MESSAGE, setStatusMessage);
}

const setStatusMessageAsync = async (data: any) => {
  return await addStatusMessageApi(data);
};

function* setStatusMessage(action: any): any {
  const user = getCurrentUser();
  const statusMessageData: IStatusMessageData = { dentistId: user?.id, ...action.payload }
  try {
    const res = yield call(setStatusMessageAsync, statusMessageData);
    if (res) {
      yield put(addStatusMessageSuccess(res.data))
    };
  } catch (error) {
    logDebug(">>> Set status Message failed", null);
  }
}
/**
 * End: set status message update
 */
/**
 * BEGIN: get availble status message 
 */
export function* watchGetStatusMessage() {
  yield takeEvery(GET_STATUS_MESSAGE, getStatusMessage);
}

const getStatusMessageAsync = async (data: any) => {
  return await getStatusMessageApi(data);
};

function* getStatusMessage(action: any): any {
  const user = getCurrentUser();
  const getStatusMessageData: IGetStatusMessageData = { dentistId: user?.id, ...action.payload }
  try {
    const res = yield call(getStatusMessageAsync, getStatusMessageData);
    if (res) {
      yield put(getStatusMessageSuccess(res.data))
    };
  } catch (error) {
    logDebug(">>> update status failed", null);
  }
}

/**
 * End: get availble status message 
 */
/**
 * BEGIN: get languageList
 */
export function* watchGetLangugeList() {
  yield takeEvery(GET_LANGUAGE_LIST, getLanguageList);
}

const getLanguageListAsync = async () => {
  return await getLanguageListApi();
};

function* getLanguageList() : any {
  try {
    const res = yield call(getLanguageListAsync);
    if (res) {
      yield put(getLanguageListSuccess(res.data))
    }; 
  } catch (error) {
    logDebug(">>> update status failed", null);
  }
}

export default function* rootSaga() {
  yield all([fork(watchsetStatusMessage), fork(watchGetStatusMessage), fork(watchGetLangugeList)]);
}
