import {
  INIT_USER,
  SIGNOUT,
  AUTHORIZE_DENTIST,
  AUTHORIZE_DENTIST_SUCCESS,
  INIT_USER_STATUS,
  STATUS_CHANGED,
  STATUS_CHANGED_SUCCESS,
  GET_STATUS,
  GET_STATUS_SUCCESS,
  GET_USER_ID,
  SET_USER_ID
} from "../actions";

export const initUser = (inituser: any) => ({
  type: INIT_USER,
  payload: inituser
});

export const getUserId = (email: string) => ({
  type: GET_USER_ID,
  payload: email
});

export const setUserId = (id: any) => ({
  type: SET_USER_ID,
  payload: id
});

export const initUserStatus = (status: boolean, user: any) => ({
  type: INIT_USER_STATUS,
  payload: { status: status, user: user },
});

export const signout = (history: any) => ({
  type: SIGNOUT,
  payload: history,
});

export const authorizeDentist = (code: string) => ({
  type: AUTHORIZE_DENTIST,
  payload: code,
});

export const authorizeDentistSuccess = (conversation: any) => ({
  type: AUTHORIZE_DENTIST_SUCCESS,
  payload: conversation,
});

export const userStatus = (status: { key: number, name: string }) => ({
  type: STATUS_CHANGED,
  payload: status,
});

export const userStatusSuccess = (status: { key: number, name: string }) => ({
  type: STATUS_CHANGED_SUCCESS,
  payload: status,
});

export const getUserStatus = (dentistId: number) => ({
  type: GET_STATUS,
  payload: dentistId,
});

export const getUserStatusSuccess = (status: { dentistId: number, status: number }) => ({
  type: GET_STATUS_SUCCESS,
  payload: status,
});
