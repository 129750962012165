import axios from "axios";
import { getAccessToken, removeValue, setCurrentUser } from "../helpers/utils";
import { StatusCode } from "../constants/status-types";

const apiFectingError = (error: any) => {
  if (error.status === StatusCode.unAuthenticated) {
    console.log('endCurrentUserSession');
    setCurrentUser(null);
    removeValue(process.env.REACT_APP_USER_SESSION!);
    removeValue(process.env.REACT_APP_USER_INFO!);
    window.open(`${process.env.REACT_APP_SMYLOR_URL}?returnTo=${window.location.href}`, "_self");
  } 
};

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  // timeout: 1000,
  headers: { Accept: "application/json", "Content-type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const updatedConfig = { ...config };
    const accessToken = getAccessToken(process.env.REACT_APP_USER_SESSION!, false);

    if (accessToken)
      updatedConfig.headers!.Authorization = `Bearer ${accessToken}`;

    return updatedConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    apiFectingError(error.response);
    return Promise.reject(error);
  }
);

export default axiosInstance;
