import { eventChannel, END } from "redux-saga";
import { logDebug } from "../helpers/utils";

declare global {
  var Twilio: any;
}

/**
 * Initializes the Twilio Conversation.
 * @param token
 */
export const initConversationSrvc = async (token: string) => {
  logDebug(">>> Initializing Twilio conversation.", null);
  const conversationClient: any = await window.Twilio.Conversations.Client.create(token, { region: process.env.REACT_APP_TWILIO_REGION });

  return eventChannel((emitter: any) => {
    conversationClient.on("connectionStateChanged", (state: string) => {
      if (state === "connecting") {
        logDebug(">>> Connecting to Twilio.", null);
      }
      if (state === "connected") {
        logDebug(">>> You are connected.", null);
      }
      if (state === "disconnecting") {
        logDebug(">>> Disconnecting from Twilio.", null);
      }
      if (state === "disconnected") {
        logDebug(">>> Disconnected.", null);
      }
      if (state === "denied") {
        logDebug(">>> Failed to connect.", null);
      }
    });

    conversationClient.on("conversationLeft", (conversation: any) => {
      logDebug(">>> Left.", null);
    });

    conversationClient.on("conversationJoined", (conversation: any) => {
      // logDebug(">>> Joined.", conversation);

      emitter({ conversationClient, conversation });

      emitter(END);
    });
    return () => { };
  });
};

/**
 * Loads the chat history.
 * @param conversation
 */
export const loadConversation = async (conversation: any) => {
  try {
    logDebug(">>> Fetching messages.", null);
    const res = await conversation.getMessages();
    return res;
  } catch (e) {
    throw e;
  }
}

/**
 * Starts listening to new messages.
 */
export const startMessageAdded = (conversation: any, callback: Function) => {
  logDebug(">>> Starting message added listener.", null);

  conversation.on("messageAdded", (m: any) => {
    logDebug(">>> New message added.", '');

    callback(m);
  });
}
/**
 * Initializes the Twilio Conversation.
 * @param token
 */
// export const initConversationSrvc = async (token: string) => {
//   logDebug(">>> Initializing Twilio conversation.", null);

//   const conversationsClient: any = await window.Twilio.Conversations.Client.create(token);

//   conversationsClient.on("connectionStateChanged", (state: string) => {
//     if (state === "connecting") {
//       logDebug(">>> Connecting to Twilio.", null);
//     }
//     if (state === "connected") {
//       logDebug(">>> You are connected.", null);
//     }
//     if (state === "disconnecting") {
//       logDebug(">>> Disconnecting from Twilio.", null);
//     }
//     if (state === "disconnected") {
//       logDebug(">>> Disconnected.", null);
//     }
//     if (state === "denied") {
//       logDebug(">>> Failed to connect.", null);
//     }
//   });

//   conversationsClient.on("conversationLeft", (conversation: any) => {
//     logDebug(">>> Left.", null);
//   });

//   conversationsClient.on("conversationJoined", (conversation: any) => {
//     logDebug(">>> Joined.", conversation);
//   });
// };
