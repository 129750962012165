import { ITemplate } from "../../models/template";
import {
  GET_SELECTED_LANGUAGE_LIST,
  GET_SELECTED_LANGUAGE_LIST_SUCCESS,
  SET_BROWSER_SELECTED_LANGUAGE,
  SET_DEFAULT_DENTIST,
  SET_SEARCH_TEXT,
  SET_SELECTED_LANGUAGE,
  SET_SIGNALR_CONNECTION
} from "../actions";

export const setSelectedLanguage = (key: string) => ({
  type: SET_SELECTED_LANGUAGE,
  payload: key,
});

export const setBrowserSelectedLanguage = (key: string) => ({
  type: SET_BROWSER_SELECTED_LANGUAGE,
  payload: key,
});

export const getLanguageSelectedList = (key: string) => ({
  type: GET_SELECTED_LANGUAGE_LIST,
  payload: key,
});

export const getLanguageSelectedListSuccess = (data: any) => ({
  type: GET_SELECTED_LANGUAGE_LIST_SUCCESS,
  payload: data,
});

export const setSignalRConnection = (data: any) => ({
  type: SET_SIGNALR_CONNECTION,
  payload: data,
});

export const setDefaulDentist = (data: any) => ({
  type: SET_DEFAULT_DENTIST,
  payload: data,
});
export const setSearchText = (data: string) => ({
  type: SET_SEARCH_TEXT,
  payload: data,
});