import axiosInstance from "./api-controller";

/**
 * Checks if a client exist.
 */
const checkClient = async (email: string) => {
    try {
        const response = await axiosInstance.get("/client/check/" + email);
        return response;
    } catch (e) {
        throw e;
    }
};

/**
 * Fetches the client details.
 */
const getClient = async (email: string) => {
    try {
        const response = await axiosInstance.get("/client/get/" + email);
        return response;
    } catch (e) {
        throw e;
    }
};

/**
 * Creates a client.
 */
const createClient = async (obj: Object) => {
    try {
        const response = await axiosInstance.post("/client/create", obj);
        return response;
    } catch (e) {
        throw e;
    }
};

/**
 * Fetches dentists.
 */
const getDentists = async (email: string | null) => {
    try {
        const response = await axiosInstance.get('/client/list/dentists/' + email);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Creates a conversation for client.
 */
const createConversation = async (Obj: any) => {

    try {
        //const response = await axiosInstance.post('/client/conversation/create', Obj);
        // const response = await axiosInstance.post(`client/conversation/GetById, ${Obj.id}/${Obj.clientId}`);
        const response = await axiosInstance.post(`client/conversation/GetById`, Obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Update a conversation record for client.
 */
const updateClientConversationRecord = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/client/conversation/UpdateRecord/' + obj);
        return response;
    } catch (e) {
        throw e;
    }
}


const getChatHistory = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/client/updatechathistory', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

const enableChatBotAPI = async (obj: any) => {
    try {
        const response = await axiosInstance.get(`/Conversation/enable/chatbot?conversationId=${obj.conversationId}&culture=${obj.culture}`);
        return response;
    } catch (e) {
        throw e;
    }
}
const cerateConversationAPI = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`client/patient`, obj);
        return response;
    } catch (e) {
        throw e;
    }
}

export {
    checkClient,
    getClient,
    createClient,
    getDentists,
    createConversation,
    updateClientConversationRecord,
    getChatHistory,
    enableChatBotAPI,
    cerateConversationAPI
};