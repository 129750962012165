const RouteRegistry = {
  landing: {
    path: '/',
  },
  clientChat: {
    path: '/client/chat',
  },
  dentist: {
    path: '/dentist',
    subroutes: {
      dentistChat: {
        path: '/chat',
      },
      messageTemplates: {
        path: '/templates',
      },
      chatWidget: {
        path: '/widget',
      },
    }
  },
  error: {
    path: '/error',
  },
}

export default RouteRegistry;