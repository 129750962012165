import {
  AUTHORIZE_DENTIST,
  AUTHORIZE_DENTIST_SUCCESS,
  GET_STATUS,
  GET_STATUS_SUCCESS,
  GET_USER_ID,
  INIT_USER,
  INIT_USER_STATUS,
  SET_USER_ID,
  STATUS_CHANGED_SUCCESS,
} from "../actions";

import IAction from "../../models/action";
import { statusNameList } from '../../constants/status-types';

const INIT_STATE = {
  loading: true,
  currentUser: null,
  userID: 0,
  status: null
};

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case INIT_USER:
    case GET_USER_ID:
      return { ...state, loading: true };
    case INIT_USER_STATUS: {
      return { ...state, loading: false, currentUser: action.payload.status ? action.payload.user : null };
    }
    case AUTHORIZE_DENTIST:
      return { ...state, loading: true };
    case AUTHORIZE_DENTIST_SUCCESS: {
      console.log('AUTHORIZE_DENTIST_SUCCESS');
      let getstatus;
      if(action.payload.status){
        getstatus = statusNameList.find((list: {key: number, name: string})=> list.key === action.payload.status)
      }
      return { ...state, loading: false, currentUser: action.payload, status : getstatus };
    }
    case STATUS_CHANGED_SUCCESS: {
      return { ...state, loading: false, status: action.payload };
    }
    case SET_USER_ID: {
      return { ...state, loading: false, userID: action.payload.id };
    }
    case GET_STATUS_SUCCESS: {
      let getstatus;
      if(action.payload.status){
        getstatus = statusNameList.find((list: {key: number, name: string})=> list.key === action.payload.status)
      }
      return { ...state, loading: false, status: getstatus };
    }
    default:
      return state;
  }
};
