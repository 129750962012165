import { IGetStatusMessageData, IStatusMessageData } from "../../models/statusMessage";
import {
  ADD_STATUS_MESSAGE,
  ADD_STATUS_MESSAGE_SUCCESS,
  GET_LANGUAGE_LIST,
  GET_LANGUAGE_LIST_SUCCESS,
  GET_STATUS_MESSAGE,
  GET_STATUS_MESSAGE_SUCCESS,
} from "../actions";

export const addStatusMessage = (data: IStatusMessageData) => ({
  type: ADD_STATUS_MESSAGE,
  payload: data
});

export const addStatusMessageSuccess = (data: any) => ({
  type: ADD_STATUS_MESSAGE_SUCCESS,
  payload: data,
});

export const getStatusMessage = (data: IGetStatusMessageData) => ({
  type: GET_STATUS_MESSAGE,
  payload: data
});

export const getStatusMessageSuccess = (data: any) => ({
  type: GET_STATUS_MESSAGE_SUCCESS,
  payload: data,
});

export const getLanguageList = () => ({ type: GET_LANGUAGE_LIST });

export const getLanguageListSuccess = (data: any) => ({
  type: GET_LANGUAGE_LIST_SUCCESS,
  payload: data,
});
