import { IGetStatusMessageData, IStatusMessageData } from '../models/statusMessage';
import axiosInstance from './api-controller';

const addStatusMessageApi = async (obj: IStatusMessageData) => {
    try {
        const response = await axiosInstance.post(`/messageTemplate/add`, obj);
        return response;
    } catch (e) {
        throw e;
    }
};

const getStatusMessageApi = async ({ culture, dentistId, status }: IGetStatusMessageData) => {
    try {
        const response = await axiosInstance.get(`/MessageTemplate/getMessage/${dentistId}/${culture}/${status}`);
        return response;
    } catch (e) {
        throw e;
    }
};

const getLanguageListApi = async () => {
    try {
        const response = await axiosInstance.get(`/Language/LanguagesList`);
        return response;
    } catch (e) {
        throw e;
    }
};

export { addStatusMessageApi, getStatusMessageApi, getLanguageListApi }