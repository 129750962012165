import { decrypt } from "../components/encyptor";
import { UserRole } from "../constants/user-role";
import { IUser } from "../models/user";
import Cookies from 'js-cookie';

const getCurrentUser = (): IUser | null => {
  try {
    const user = getValue(process.env.REACT_APP_USER_INFO!);
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    return null;
  }
};

const setCurrentUser = (user: IUser | null) => {
  try {
    if (user) {
      setValue(process.env.REACT_APP_USER_INFO!, JSON.stringify(user));
    } else {
      removeValue(process.env.REACT_APP_USER_INFO!);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

const setValue = (key: string, value: any, needEncrypt = false) => {
  try {
    let prepValue = value;
    // if (process.env.REACT_APP_HASH_STORAGE && needEncrypt) {
    //   prepValue = crypto.AES.encrypt(value.toString(), process.env.REACT_APP_LOCAL_STORAGE_SECRET);
    // }
    localStorage.setItem(key, prepValue);
    Cookies.set(key, value, { domain: `${process.env.REACT_APP_DOMAIN_URL}` });
  } catch (e) {
    if (process.env.REACT_APP_DEBUG === "true") {
      console.log("-------- storage setting error --------");
      console.log("value ", value);
      console.log(e);
    }
  }
};

const getValue = (key: string, needDecrypt = false) => {
  try {
    const prepValueLocal: any = localStorage.getItem(key);
    const prepValue = Cookies.get(key);
    // if (prepValue && process.env.REACT_APP_HASH_STORAGE && needDecrypt) {
    //   prepValue = crypto.AES.decrypt(prepValue, process.env.REACT_APP_LOCAL_STORAGE_SECRET).toString(crypto.enc.Utf8);
    // }
    // if (process.env.REACT_APP_DEBUG === "true") {
    //   return prepValueLocal;
    // }
    // return prepValue;
    if (prepValue) {
      return prepValue;
    } else if (prepValueLocal) {
      return prepValueLocal;
    }
  } catch (e) {
    if (process.env.REACT_APP_DEBUG === "true") {
      console.log("-------- storage getting error --------");
      console.log("key ", key);
      console.log(e);
    }
    return null;
  }
};

const getAccessToken = (key: string, needDecrypt = false) => {
  try {
    const prepValue = Cookies.get(key);
    if(prepValue){
      return prepValue;
    }
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === "true") {
      console.log("-------- storage getting error --------");
      console.log("key ", key);
      console.log(error);
    }
    return null;
  }
};

const removeValue = (key: string) => {
  try {
    console.log('removeValue', key);
    localStorage.removeItem(key);
    Cookies.remove(key, { domain: `${process.env.REACT_APP_DOMAIN_URL}` });
    // Cookies.remove(key, { domain: 'smylor.ch' });
  } catch (e) {
    if (process.env.REACT_APP_DEBUG === "true") {
      console.log("-------- storage removing error --------");
      console.log("key ", key);
      console.log(e);
    }
  }
};

const logDebug = (name: string, message: any) => {
  if (process.env.REACT_APP_DEBUG === "true") {
    console.log(name);

    if (message) console.log(message);
  }
};

export const generateResultObject = (array: string[]) => {

  const [quection, ...answers] = array;

  const msg = {
    msgType: "replay",
    text: quection,
    suggestedActions: answers.map(obj => ({ type: "reply", value: obj }))
  }
  return msg
};

export const userRoleDentist = (role: number) => {
  if (role === UserRole.DENTIST || role === UserRole.PARTICIPANT) return true;
  else return false;
};

export {
  getCurrentUser,
  setCurrentUser,
  setValue,
  getValue,
  removeValue,
  logDebug,
  getAccessToken
};
