import { IUpdateChatBotData } from '../models/dentist';
import axiosInstance from './api-controller';

/**
 * Authorize dentist.
 */
const authorizeDentist = async (code: string) => {
    try {
        const response = await axiosInstance.get('/user/authorize/' + code);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Fetches the clients by dentist id.
 * @param dentistId Id of the dentist.
 */
//const getClientsByDentistId = async (dentistId: number) => {
const getClientsByDentistId = async (email: string | undefined | null) => {
    try {
        //const response = await axiosInstance.get('/dentist/clients/' + dentistId);
        const response = await axiosInstance.get(`dentist/list/patient/${email}`);
        return response;

    } catch (e) {
        throw e;
    }
}

/**
 * Get the conversation info for dentist and the selected client.
 */
const getConversationDentist = async (Obj: any) => {
    try {
        //const response = await axiosInstance.post('/dentist/conversation', Obj);
        const response = await axiosInstance.post(`dentist/conversation/GetById/${Obj.id}/${Obj.dentistId}`);

        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Creates a new message template.
 */
const createTemplate = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/template/add', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Lists templates.
 */
const listTemplates = async (dentistId: number, culture: string) => {
    try {
        const response = await axiosInstance.get('/template/list/' + dentistId + '?culture=' + culture);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Updates a template.
 */
const updateTemplate = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/template/update', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Deletes a template.
 */
const deleteTemplateById = async (templateId: number) => {
    try {
        const response = await axiosInstance.post('/template/delete/' + templateId);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Updates a template category name.
 */
const updateTemplateCategoryAPI = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/Template/category/edit', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Updates a template category name.
 */
const deleteTemplateCategoryAPI = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/Template/category/delete', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * 
 */
const sortTemplatesOrder = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/template/sort', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Update a conversation record for dentist.
 */
const updateDentistConversationRecord = async (obj: any) => {
    try {
        const response = await axiosInstance.post('/dentist/conversation/UpdateRecord/' + obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * Lists Categories.
 */
const listCategories = async (id: number, culture: string) => {
    try {
        const response = await axiosInstance.get('/Template/category/' + id +'?culture=' + culture);
        return response;
    } catch (e) {
        throw e;
    }
}

const updatedentistStatus = async (obj: { email: string, status: number, isReset: boolean }) => {
    try {
        const response = await axiosInstance.post(`/dentist/updateStatus`, obj);
        return response;
    } catch (e) {
        throw e;
    }
};

const getCurrentDentistStatus = async (dentistId: number) => {
    try {
        const response = await axiosInstance.get(`/Dentist/getDentistStatus/${dentistId}`);
        return response;
    } catch (e) {
        throw e;
    }
};

/**
 * get User Id.
 */
const getUpdateUserIdAPI = async (email: string) => {
    try {
        const response = await axiosInstance.get(`/User/details/${email}`);
        return response;
    } catch (e) {
        throw e;
    }
};

/**
 * enable chat bot.
 */

const updateChatBotStatusAPI = async (obj: IUpdateChatBotData) => {
    try {
        const response = await axiosInstance.post('/Conversation/ChangeBotStatus', obj);
        return response;
    } catch (e) {
        throw e;
    }
}

/**
 * get Tag List templates.
 */
const listTaglist = async () => {
    try {
        const response = await axiosInstance.get('/Template/taglist/');
        return response;
    } catch (e) {
        throw e;
    }
}
export {
    authorizeDentist,
    getClientsByDentistId,
    getConversationDentist,
    createTemplate,
    listTemplates,
    updateTemplate,
    deleteTemplateById,
    sortTemplatesOrder,
    updateDentistConversationRecord,
    listCategories,
    updatedentistStatus,
    getCurrentDentistStatus,
    updateTemplateCategoryAPI,
    deleteTemplateCategoryAPI,
    updateChatBotStatusAPI,
    getUpdateUserIdAPI,
    listTaglist
}