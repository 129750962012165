import {
  ADD_STATUS_MESSAGE,
  ADD_STATUS_MESSAGE_SUCCESS,
  GET_LANGUAGE_LIST,
  GET_LANGUAGE_LIST_SUCCESS,
  GET_STATUS_MESSAGE,
  GET_STATUS_MESSAGE_SUCCESS,
} from "../actions";

import IAction from "../../models/action";

const INIT_STATE = {
  loading: false, 
  statusMessageList:  null,
  languageList: null
};

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case ADD_STATUS_MESSAGE:
    case GET_STATUS_MESSAGE:
    case GET_LANGUAGE_LIST:
      return { ...state, loading: true };
    case ADD_STATUS_MESSAGE_SUCCESS: {
      return { ...state, loading: false, statusMessageList: action.payload };
    }
    case GET_STATUS_MESSAGE_SUCCESS: {
      return { ...state, loading: false, statusMessageList: action.payload };
    }
    case GET_LANGUAGE_LIST_SUCCESS: {
      return { ...state, loading: false, languageList: action.payload };
    }
    default:
      return state;
  }
};
